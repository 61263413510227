

const Footer = () => {
  return (
    <div className="py-4">
      Copyright © 2022 MoveTowardHappy.
    </div>
  );
};
export default Footer;
