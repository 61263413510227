
import { useContext } from "react";
import { wwwApp } from "../../urls"
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCog, faBrain, faBullseye, faChartLine, faArrowAltCircleUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { AuthContext } from "../../context/AuthContext";
import { api } from "../../api/api.js";
import '../../styles/styles.css';


const Navbar = () => {
    const { user } = useContext(AuthContext);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const linkStyles = {
        color: '#6c757d',
        backgroundColor: '#ffffff',
    };

    const activeLinkStyles = {
        color: '#000',
        backgroundColor: '#D7E4B8',
        border: '0px',
    };

    const handleClick = async (e) => {
        e.preventDefault();
        dispatch({ type: "LOGOUT" });
        try {
            // Await the completion of the POST request
            //await api.post(`/api/auth/logout`);
            // Navigate to the home page only after the POST request is completed
            navigate("/home");
        } catch (err) {
            console.error(err);
            // Handle any errors that occur during the POST request
        }
    };
    return (
        <div id="sidebar-nav" className="list-group rounded-0 text-sm-start min-vh-100">
            <NavLink to="/"
                className="list-group-item  "
                style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}
                data-bs-parent="#sidebar">
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                &nbsp;Home
            </NavLink>
            {user && (
                <NavLink to="/workspace"
                    className="list-group-item  "
                    style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}
                    data-bs-parent="#sidebar">
                    <FontAwesomeIcon icon={faCog} className="mr-2" />
                    &nbsp;Dashboard
                </NavLink>
            )}
            <NavLink to="/ai"
                className="list-group-item  "
                style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}
                data-bs-parent="#sidebar">
                <FontAwesomeIcon icon={faBrain} className="mr-2" />
                &nbsp;AI Plan
            </NavLink>
            <NavLink to="/goals"
                className="list-group-item  "
                style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}
                data-bs-parent="#sidebar">
                <FontAwesomeIcon icon={faBullseye} className="mr-2" />
                &nbsp;Goals
            </NavLink>

            <NavLink to="/habits"
                className="list-group-item  "
                style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}
                data-bs-parent="#sidebar">
                <FontAwesomeIcon icon={faChartLine}
                    className="mr-2" />
                &nbsp;Habits
            </NavLink>
            {user && (
                <NavLink to="/plans"
                    className="list-group-item  "
                    style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}
                    data-bs-parent="#sidebar">
                    <FontAwesomeIcon icon={faArrowAltCircleUp} className="mr-2" />
                    &nbsp;Upgrade
                </NavLink>
            )}
            <NavLink to="/instructions"
                className="list-group-item  "
                style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}
                data-bs-parent="#sidebar">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                &nbsp;Help
            </NavLink>
        </div>
    );
};

export default Navbar;