// GoalSummaryChart.jsx
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const renderColorfulLegendText = (value, entry, index) => {
    const { color } = entry;
    return <span key={`${entry.name}-${index}`} style={{ color }}>{value}</span>;
};

const GoalSummaryChart = ({ goalStats }) => {
    const data = [
        { name: 'Not Started', value: goalStats.notStarted, fill: '#ced4da' },
        { name: 'Off-Track', value: goalStats.offTrack, fill: '#f9c74f' },
        { name: 'On-Track', value: goalStats.onTrack, fill: '#277da1' },
        { name: 'Complete', value: goalStats.complete, fill: '#90be6d' },
    ];

    // console.log("In the Chart: goalStatusCounts: " + JSON.stringify(goalStats));

    return (
        <ResponsiveContainer width="100%" height={380}>
            <PieChart>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))
                    }
                </Pie>
                <Tooltip />
                <Legend formatter={renderColorfulLegendText} />
            </PieChart>
        </ResponsiveContainer>

    );
};

export default GoalSummaryChart;
