import '../../../src/styles/styles.css';

import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks, faCheckCircle, faFrog, faTrash, faBullseye } from "@fortawesome/free-solid-svg-icons";
import { Card } from 'react-bootstrap';

const ToDoItem = ({ index, toDo, deleteToDo, toggleToDoFocus, toggleToDoFrog, toggleTodoComplete }) => {
    return (
        <Draggable draggableId={toDo._id} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`mb-1 shadow-sm ${toDo.complete ? 'complete-item' : 'not-started-item'}`}
                    style={{ minHeight: '160px' }}
                >
                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className={`p-1 ${toDo.complete ? 'text-success' : 'text-secondary'}`}
                                />
                            </div>
                            <div className="flex-grow-1">
                                <div {...provided.dragHandleProps}>

                                    <Card.Title className="text-dark mb-2">
                                        To-Do: {toDo.text || 'Default ToDo Value'}
                                    </Card.Title>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <small className="text-muted py-1">
                                            <div className='row'>
                                                <div className='col'>
                                                    Current Focus: <strong>{toDo.focus ? 'Yes' : 'No'}</strong>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    Difficult Item: <strong>{toDo.frog ? 'Yes' : 'No'}</strong>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    Complete: <strong>{toDo.complete ? 'Yes' : 'No'}</strong>
                                                </div>
                                            </div>
                                        </small>
                                        <div>
                                            <div
                                                className={`dashboard-btn`}
                                                onClick={() => deleteToDo(toDo._id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </div>
                                            <div
                                                className={`${toDo.focus ? 'dashboard-btn-on' : 'dashboard-btn'}`}
                                                onClick={() => toggleToDoFocus(toDo._id, !toDo.focus)}
                                            >
                                                <FontAwesomeIcon icon={faBullseye} />
                                            </div>
                                            <div
                                                className={`${toDo.frog ? 'dashboard-btn-on' : 'dashboard-btn'}`}
                                                onClick={() => toggleToDoFrog(toDo._id, !toDo.frog)}
                                            >
                                                <FontAwesomeIcon icon={faFrog} />
                                            </div>
                                            <div
                                                className={`${toDo.complete ? 'dashboard-btn-complete' : 'dashboard-btn'}`}
                                                onClick={() => toggleTodoComplete(toDo._id, !toDo.complete)}
                                            >
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card >
            )}
        </Draggable >
    );
};

export default ToDoItem;
