import { useState } from "react";
import { api } from "../api/api.js";


const Test = () => {
    // Removed sidebar state and toggle function

    return (
        <div className="container">
            {/* Button to run the script */}
            <button className="btn btn-primary" onClick={() => {
                // Call the runScript function from the goals controller
                api.post('/api/goal/script', { method: 'POST' })
            }}>
                Run Script
            </button>
        </div>
    );
};
export default Test;