export const minute = 60 * 1000;
export const hour = 60 * minute;
export const day = 24 * hour;
export const week = 7 * day;
export const year = 365 * day;

export const todayStart = () => {
    var now = new Date().toISOString();
    const temp = now.substring(0, 10) + "T00:00:00Z"
    const tempDate = new Date(temp);
    return tempDate;
}

export const isToday = (date) => {
    const today = todayStart();
    return date.toDateString() === today.toDateString();
};

export const isFutureDate = (increment, day) => {
    const tempDate = new Date(day);
    tempDate.setDate(tempDate.getDate() + increment);
    return tempDate > todayStart();
};

// export const todayStart = () => {
//     const today = new Date();
//     today.setHours(0, 0, 0, 0);
//     return today;
// };


export function formatDateToUS(dateString) {
    const date = new Date(dateString);
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
}

// export const startOfDate = (date) => {
//     let temp = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
//     return temp;
// }
// export const startOfDate = (date) => {
//     var now = new Date(date).toISOString();
//     const temp = now.substring(0, 10) + "T00:00:00Z"
//     const tempDate = new Date(temp);
//     return tempDate;
// }

export const startOfDate = (date) => {
    var now = new Date(date).toISOString();
    const temp = now.substring(0, 10) + "T00:00:00Z"
    const tempDate = new Date(temp);
    return tempDate;
}

export const removeDateTime = (date) => {
    console.log("***date is " + date);
    return date.split('T')[0];
}

export const lastXDays = (n, array) => {
    const toDate = todayStart().getTime();
    const fromDate = toDate - (n * day);
    // console.log("from date is " + new Date(fromDate));
    // console.log("n is " + n);
    let count = 0;
    for (const x of array) {
        if (between(fromDate, new Date(x).getTime(), toDate)) {
            count++;
        }
    };

    return count;
}
export const formatToISOString = (date) => {
    const newDate = new Date(date);
    return newDate.toISOString();
};


export const lastXDaysNew = (n, array) => {

    const temp = 5;
    // console.log("n is " + n);
    // console.log("temp is " + temp);
    // console.log("array is " + array);

    return temp;
}

export const inDateArray = (theDate, array) => {
    const contains = array.some(date => new Date(date).getTime() === theDate.getTime());
    return contains;
}

export const between = (from, date, to) => {
    // console.log("from is " + from + " date is " + date + " to is " + to)
    const temp = ((from <= date) && (date <= to));
    // console.log("temp is " + temp);
    return temp;
}


export const daysBetween = (startDate, endDate) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    const startDateMs = startDate.getTime();
    const endDateMs = endDate.getTime();

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(endDateMs - startDateMs);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
};
