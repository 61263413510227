import axios from 'axios';
import { golApp, apiApp } from '../urls';

export const api = axios.create({
    baseURL: apiApp,
    timeout: 40000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    },
});
