import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek, faCalendarAlt, faCalendar } from "@fortawesome/free-solid-svg-icons";

const HabitSummaryChart = ({ habitStats }) => {
    // console.log("rawdata", rawData);
    const [timeFrame, setTimeFrame] = useState("week");
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const chartData = Object.values(habitStats).map((habitData) => ({
            dateFormatted: 'Today', // or any other date formatting
            Complete: habitData.week,
            Inception: habitData.inception,
            Month: habitData.month,
            Year: habitData.year,
        }));
        setFilteredData(chartData);
    }, [habitStats]);

    return (
        <div className="chart-container m-0 p-0">
            <div className="button-group d-flex justify-content-end m-0">
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "week" ? "active" : ""}`}
                    onClick={() => setTimeFrame("week")}
                >
                    <FontAwesomeIcon icon={faCalendarWeek} />
                </button>
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "month" ? "active" : ""}`}
                    onClick={() => setTimeFrame("month")}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </button>
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "year" ? "active" : ""}`}
                    onClick={() => setTimeFrame("year")}
                >
                    <FontAwesomeIcon icon={faCalendar} />
                </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={filteredData} margin={{ top: 5, right: 0, left: -20, bottom: 0 }} // Adjust left margin here
                >

                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dateFormatted" tick={{ fontSize: 12 }} />
                    <YAxis
                        tick={{ fontSize: 12 }}
                        allowDecimals={false} // This ensures only whole numbers are displayed

                    />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="Complete" stroke="#277da1" fill="#f9c74f" />
                </AreaChart>
            </ResponsiveContainer>

        </div>
    );
};

export default HabitSummaryChart;