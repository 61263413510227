import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import HabitItem from './HabitItem';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faAnglesRight, faChevronLeft, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";

import { todayStart, isToday, isFutureDate } from "../../utils/theDateHelper.js";


const HabitWidget = ({ habits, updateHabitOrder, totalHabits, removeHabit, toggleHabitComplete, toggleHabitFocus, toggleHabitFrog, updateHabitStart, updateHabitEnd, updateHabitDetail }) => {

    const onDragEnd = async (dragResult) => {
        const { source, destination } = dragResult;
        if (!destination || source.index === destination.index) return;
        const reorderedHabits = Array.from(habits);
        const [reorderedHabit] = reorderedHabits.splice(source.index, 1);
        reorderedHabits.splice(destination.index, 0, reorderedHabit);
        updateHabitOrder(reorderedHabits);
    };

    const [habitDay, setHabitDay] = useState(todayStart());
    const changeHabitDay = (increment) => {
        const tempDate = new Date(habitDay);
        tempDate.setDate(tempDate.getDate() + increment);
        setHabitDay(tempDate);
    };

    const resetHabitDay = () => setHabitDay(todayStart());

    const isEditable = (date) => {
        const today = todayStart();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return date >= yesterday && date <= today;
    };

    return (
        <div className="col-md-8 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold secondary-color">
                    Daily Habits
                </div>
                <div className="flex-grow-1">
                    <div className="row p-2">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <div className="mx-3 text-center form-control border" style={{ width: '210px' }}>
                                {habitDay.toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            {[-7, -1, 1, 7].map((increment, idx) => (
                                <button
                                    key={increment}
                                    className={`btn btn-sm ${idx === 0 ? 'me-1' : idx === 1 ? 'mx-1' : 'ms-1'}`}
                                    style={{ backgroundColor: '#277da1', color: 'white' }}
                                    onClick={() => changeHabitDay(increment)}
                                    disabled={idx === 2 ? isToday(habitDay) : isFutureDate(increment, habitDay)}
                                >
                                    <FontAwesomeIcon icon={idx === 0 ? faAnglesLeft : idx === 1 ? faChevronLeft : idx === 2 ? faChevronRight : faAnglesRight} />
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col px-3">
                            {habits.length === 0 ? (
                                <div className="text-center p-3">
                                    <p>No habits yet! Ready to set some?</p>
                                    <a href="/habits" className="btn secondary-btn">Go to Habits Page</a>
                                </div>
                            ) : (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="habits">
                                        {provided => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-wrap">
                                                {habits.map((user2habit, index) => (
                                                    <div key={user2habit._id} className="col-md-6 col-sm-12 p-1">
                                                        <HabitItem
                                                            index={index}
                                                            user2habit={user2habit}
                                                            removeHabit={removeHabit}
                                                            toggleHabitComplete={toggleHabitComplete}
                                                            toggleHabitFocus={toggleHabitFocus}
                                                            toggleHabitFrog={toggleHabitFrog}
                                                            updateHabitStart={updateHabitStart}
                                                            updateHabitEnd={updateHabitEnd}
                                                            updateHabitDetail={updateHabitDetail}
                                                            habitDay={habitDay}
                                                            isEditable={isEditable}
                                                        />
                                                    </div>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}
                        </div>
                    </div>
                </div>
                <div className="p-2 mt-auto secondary-color">
                    {totalHabits} Daily Habits
                </div>
            </div>
        </div>
    );
};

export default HabitWidget;
