import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import Footer from "../components/page/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCirclePause, faCircleChevronDown, faCircleChevronUp, faCirclePlus, faCircleMinus, faBullseye, faFrog } from "@fortawesome/free-solid-svg-icons";


const Home = ({ title }) => {
    return (
        <div className="conatainer p-0 m-0 h-100 d-flex flex-column">
            <Navbar />
            <div className="m-0 p-3 ">MTH <a className="text-dark" href="https://gol.movetowardhappy.com">Goals & Habits</a> offers the following features
                <br /><br />
                Goals Page
                <ol>
                    <li>Search for the goal(s) most appropriate for your life - use filters as needed.</li>
                    <li>Add the most relevant goal(s) to your Dashboard.</li>
                    <li>Remove goal(s) that are no longer a focus.</li>
                </ol>
                Habits Page
                <ol>
                    <li>Search for the habit(s) most appropriate for your goals - use filters as needed.</li>
                    <li>Add the most relevant habit(s) to your Dashboard</li>
                    <li>Remove habit(s) that are no longer a focus</li>
                </ol>
                Manage Page
                <ol>
                    <li>Goal Widget</li>
                    <ul>
                        <li>
                            Click appropriate icon to reflect goal's current status -
                            Paused <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faCirclePause} /></div>,
                            Off-Track <div className="btn p-1 m-1 border text-warning"><FontAwesomeIcon className=" " icon={faCircleChevronDown} /></div>,
                            On-Track <div className="btn p-1 m-1 border text-success"><FontAwesomeIcon className=" " icon={faCircleChevronUp} /></div>,
                            Complete <div className="btn p-1 m-1 border text-primary"><FontAwesomeIcon className=" " icon={faCheckCircle} /></div>.
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faCircleMinus} /></div>
                            to remove goal(s) that are no longer a focus.
                        </li>
                    </ul>
                    <li>Habit Widget</li>
                    <ul>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faCheckCircle} /></div> to mark habit status Complete for the day, and click
                            <div className="btn p-1 m-1 border text-primary"><FontAwesomeIcon className=" " icon={faCheckCircle} /></div> to mark habit status as Not-Started.
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faCircleMinus} /></div>
                            to remove goal(s) that are no longer a focus.
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faBullseye} /></div>
                            to designate a habit as a current focus item.
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faFrog} /></div>
                            to designate a habit as a difficult habit which you should accomplish first - "Eat the Frog".
                        </li>
                        <li>
                            <u><i><b>Note:</b></i></u> Habits reset as <u>Paused</u> everyday.<br /><br />
                        </li>
                    </ul>
                    <li>Todo Widget</li>
                    <ul>
                        <li>
                            <div className="d-flex align-items-center">
                                Add a todo by typing text into <input className="form-control d-flex mx-2" style={{ width: '40px' }} />
                                and then select "Return" or click <div className="btn p-1 m-1 border text-success"><FontAwesomeIcon className="" icon={faCirclePlus} /></div>
                            </div>
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faCheckCircle} /></div>to mark todo Complete and click
                            <div className="btn p-1 m-1 border text-primary"><FontAwesomeIcon className=" " icon={faCheckCircle} /></div> to return status to Not-Started.
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faCircleMinus} /></div>
                            to remove todo.
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faBullseye} /></div>
                            to designate a todo as a current focus item.
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faFrog} /></div>
                            to designate a todo as a  difficult todo which you should accomplish first - "Eat the Frog".
                        </li>
                    </ul>
                    <li>Budget Widget</li>
                    <ul>
                        <li>
                            <div className="d-flex align-items-center">
                                Add a budget Item by typing the appropriate category, description, and amount into the respective <input className="form-control d-flex mx-2" style={{ width: '40px' }} />'s
                                and then click <div className="btn p-1 m-1 border text-success"><FontAwesomeIcon className="" icon={faCirclePlus} /></div>
                            </div>
                        </li>
                        <li>
                            Click <div className="btn p-1 m-1 border "><FontAwesomeIcon className=" " icon={faCircleMinus} /></div>
                            to delete the budget item.
                        </li>
                    </ul>
                    <li>Work Trend Widget</li>
                    <ul>
                        <li>
                            <div className="d-flex align-items-center">
                                The work trend widget displays the number of habits and todos you complete per day. If you have completed a todo, be sure to mark
                                it complete before deleting in order to have the todo reflected in the work trend graph.
                            </div>
                        </li>
                    </ul>
                </ol>

            </div>
            <Footer />
        </div >
    );
};


export default Home;