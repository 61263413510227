import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Header = () => {
    const { user } = useContext(AuthContext);

    return (
        <>
            {user ? (
                // Add content here if user exists
                <></>
            ) : (
                <>
                    <div className="row">
                        <div className="col primary-color py-4 display-4 text-center">
                            <h1 className="display-4 text-center text-secondary">
                                Manage Your Life
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col primary-color py-3 text-center">
                            <div className="btn border border-secondary bg-white">
                                <Link className="text-dark text-decoration-none" to="/register">
                                    Register Today
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Header;
