import React from "react";
import ToDoSummaryChart from './ToDoSummaryChart.jsx';

const ToDoStatusWidget = ({ toDoChartData, totalToDos }) => {
    const content = totalToDos === 0 ? (
        <div className="text-center p-3">
            <p>No To-Dos yet! Create some</p>
        </div>
    ) : (
        <ToDoSummaryChart toDoChartData={toDoChartData} totalToDos={totalToDos} />
    );

    return (
        <div className="col-md-4 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold primary-color">
                    To-Dos
                </div>
                <div className="flex-grow-1">
                    {content}
                </div>
                <div className="p-2 text-muted primary-color">
                    {totalToDos} To-Dos
                </div>
            </div>
        </div>
    );
};

export default ToDoStatusWidget;
