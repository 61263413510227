import { DragDropContext, Droppable } from "react-beautiful-dnd";
import GoalItem from './GoalItem';

const GoalWidget = ({ goals, totalGoals, updateGoalOrder, toggleGoalStatus, updateGoalDetail, updateGoalMeasure, updateGoalDeadline, removeGoal }) => {

    const onDragEnd = async (dragResult) => {
        const { source, destination } = dragResult;
        if (!destination || source.index === destination.index) return;
        const reorderedGoals = Array.from(goals);
        const [reorderedGoal] = reorderedGoals.splice(source.index, 1);
        reorderedGoals.splice(destination.index, 0, reorderedGoal);
        updateGoalOrder(reorderedGoals);
    };

    return (
        <div className="col-md-8 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold primary-color">
                    Goals
                </div>
                <div className="row">
                    <div className="col px-3">
                        {goals.length === 0 ? (
                            <div className="text-center p-3">
                                <p>No goals yet! Ready to set some?</p>
                                <a href="/goals" className="btn secondary-btn">Go to Goals Page</a>
                            </div>
                        ) : (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="goals">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className="row w-100 m-0">
                                            {goals.map((user2goal, index) => (
                                                <div key={user2goal._id} className="col-md-6 col-sm-12 p-1">
                                                    <GoalItem
                                                        user2goal={user2goal}
                                                        index={index}
                                                        toggleGoalStatus={toggleGoalStatus}
                                                        updateGoalDetail={updateGoalDetail}
                                                        updateGoalMeasure={updateGoalMeasure}
                                                        updateGoalDeadline={updateGoalDeadline}
                                                        removeGoal={removeGoal}
                                                    />
                                                </div>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </div>
                </div>
                <div className="p-2 footer-border mt-auto primary-color">
                    {totalGoals} Goals
                </div>
            </div>
        </div>
    );
};

export default GoalWidget;


// const suggestMilestones = async (goalDescription) => {
//     try {
//         const data = {
//             prompt: `Create a list of milestones for the following goal: ${goalDescription}`,
//             // Other necessary parameters for OpenAI API
//         };

//         const response = await fetch("https://api.openai.com/v1/engines/davinci/completions", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer YOUR_OPENAI_API_KEY`
//             },
//             body: JSON.stringify(data)
//         });

//         const result = await response.json();
//         console.log(result.choices[0].text);
//         return result.choices[0].text; // Parse and return the suggestion
//     } catch (error) {
//         console.error("Error suggesting milestones: ", error);
//         return null;
//     }
// };
